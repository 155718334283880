<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">
<!--        <div class="content-tabs__item " v-bind:class="{active: tab === 1}" @click="changeTab(1)">-->
<!--          <div class="content-tabs__ico">-->
<!--           -->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="content-top-line-wrap">
<!--        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />-->
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block">
<!--          <MainButton class="btn-fit-content ml-3"-->
<!--                    :value="'Add Keyword'"-->
<!--                    :ico="'ico'"-->
<!--        >-->
<!--          <template slot="ico">-->
<!--            <PlusIconSVG class="btn-icon-plus"/>-->
<!--          </template>-->
<!--        </MainButton>-->
      </div>
    </div>




  </div>
</template>



<script>
  // import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  export default {
    name: "ExportRequestsHead",
    components: {
      // ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data(){
      return{

      }
    },

    methods: {


    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

